.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #3bedd5;
}

#map {
  height: 60vh;
}

header {
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

header .title{
  font-size: 24px;
  color: #3bedd5;
  font-weight: 500;
  cursor: pointer;
}

header .icon {
  font-size: 22px;
  color: #3bedd5;
  cursor: pointer;
}

input[type="range"]::-webkit-slider-thumb { 
  border: 1px solid silver;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}
.sidebar-menu {
  border: none !important;
  padding-top: 5vh;
}
.sidebar-menu .fa-bars{
  position: absolute;
  left: 20px;
  top: 10px;
  font-size: 22px;
  color: #3bedd5;
  cursor: pointer;
}
.sidebar-menu .item > div.text{
  margin-bottom: 15px;
}

.sidebar-menu .ui.star.rating .icon {
  font-size: 20px;
}
.sidebar-menu .ui.star.rating .selected.icon {
  color: #3bedd5!important;
  text-shadow: none !important;
}
.sidebar-menu .ui.star.rating .active.icon {
  color: #3bedd5!important;
  text-shadow: none !important;
}
.sidebar-menu .star-rating > i:focus{ 
  outline: none;
}
.sidebar-menu .reset-button {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 22px;
  color: #3bedd5;
  cursor: pointer;
}