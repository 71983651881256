.Place {
    height: 35vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
}
.cover-wrapper{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
}
.blur-background{
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    filter: blur(2px);
    opacity: .8;
}
.content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    position: relative;
    width: 100%;
    height: 100%;
    font-weight: 600;
}
.name {
    font-size: 24px;
    margin-bottom: 20px;
    color: #fff;
    font-weight: 700;
    line-height: 1.5;
}
.name:hover {
    color: #fff;
}
.name:active {
    color: #3bedd5;
}
.info {
    font-size: 16px;
}
.info > div{
    margin-bottom: 10px;
}
.rating {
    display: flex;
    align-items: center;
    justify-content: center;
}
.rating .number {
    margin-right: 10px;
}
.fa-star{
    width: 20px !important;
}
.star-rating{
    color: rgb(217, 217, 217);
    width: 100px;
    position: relative;
}
.star-rating-top{
    color: #3bedd5;
    position: absolute;
    overflow: hidden;
    display: flex;
    top: 3px;
}
.red {
    color: #d32a35;
}
.green {
    color: #3bedd5;
}